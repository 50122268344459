@font-face {
    font-family: "SF Pro Text";
    src: url('../fonts/english/sfprotext-regular.eot');
    /* IE9*/
    src: url('../fonts/english/sfprotext-regular.eot?#iefix') format("embedded-opentype"),
        /* IE6-IE8 */
        url('../fonts/english/sfprotext-regular.woff2') format("woff2"),
        /* chrome firefox */
        url('../fonts/english/sfprotext-regular.woff') format("woff"),
        /* chrome firefox */
        url('../fonts/english/sfprotext-regular.ttf') format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url('../fonts/english/sfprotext-regular.svg#SF Pro Text') format("svg");
    /* iOS 4.1- */
}

@font-face {
    font-family: "SF Pro Display";
    src: url('../fonts/english/sfprodisplay-bold.eot');
    /* IE9*/
    src: url('../fonts/english/sfprodisplay-bold.eot?#iefix') format("embedded-opentype"),
        /* IE6-IE8 */
        url('../fonts/english/sfprodisplay-bold.woff2') format("woff2"),
        /* chrome firefox */
        url('../fonts/english/sfprodisplay-bold.woff') format("woff"),
        /* chrome firefox */
        url('../fonts/english/sfprodisplay-bold.ttf') format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url('../fonts/english/sfprodisplay-bold.svg#SF Pro Display') format("svg");
    /* iOS 4.1- */
}


@font-face {
    font-family: "Jameel Noori Nastaleeq";
    src: url('../fonts/urdu/JameelNooriNastaleeq.eot');
    /* IE9*/
    src: url('../fonts/urdu/JameelNooriNastaleeq.eot?#iefix') format("embedded-opentype"),
        /* IE6-IE8 */
        url('../fonts/urdu/JameelNooriNastaleeq.woff2') format("woff2"),
        /* chrome firefox */
        url('../fonts/urdu/JameelNooriNastaleeq.woff') format("woff"),
        /* chrome firefox */
        url('../fonts/urdu/JameelNooriNastaleeq.ttf') format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url('../fonts/urdu/JameelNooriNastaleeq.svg#JameelNooriNastaleeq') format("svg");
    /* iOS 4.1- */

    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'Jameel Noori Nastaleeq';
//     src: url('../fonts/urdu/JameelNooriNastaleeq.eot');
//     src: url('../fonts/urdu/JameelNooriNastaleeq.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/urdu/JameelNooriNastaleeq.woff2') format('woff2'),
//         url('../fonts/urdu/JameelNooriNastaleeq.woff') format('woff'),
//         url('../fonts/urdu/JameelNooriNastaleeq.ttf') format('truetype'),
//         url('../fonts/urdu/JameelNooriNastaleeq.svg#JameelNooriNastaleeq') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }


@font-face {
    font-family: "Scheherazade";
    src: url('../fonts/urdu/Scheherazade-Bold.eot');
    /* IE9*/
    src: url('../fonts/urdu/Scheherazade-Bold.eot?#iefix') format("embedded-opentype"),
        /* IE6-IE8 */
        url('../fonts/urdu/Scheherazade-Bold.woff2') format("woff2"),
        /* chrome firefox */
        url('../fonts/urdu/Scheherazade-Bold.woff') format("woff"),
        /* chrome firefox */
        url('../fonts/urdu/Scheherazade-Bold.ttf') format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url('../fonts/urdu/Scheherazade-Bold.svg#Scheherazade-Bold') format("svg");
    /* iOS 4.1- */

    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/english/icomoon.eot?ft6s5m');
    src: url('../fonts/english/icomoon.eot?ft6s5m#iefix') format('embedded-opentype'),
        url('../fonts/english/icomoon.ttf?ft6s5m') format('truetype'),
        url('../fonts/english/icomoon.woff?ft6s5m') format('woff'),
        url('../fonts/english/icomoon.svg?ft6s5m#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icon-calling:before {
    content: "\e917";
}

.icon-icon-lock:before {
    content: "\e918";
}

.icon-icon-payment-outline:before {
    content: "\e912";
}

.icon-icon-cvv:before {
    content: "\e90f";
}

.icon-icon-camera .path1:before {
    content: "\e908";
    color: #fff;
}

.icon-icon-camera .path2:before {
    content: "\e909";
    color: #fff;
    margin-left: -1.1669921875em;
}

.icon-icon-camera .path3:before {
    content: "\e90d";
    color: $blue;
    margin-left: -1.1669921875em;
}

.icon-icon-caret-down:before {
    content: "\e90e";
}

.icon-icon-avtar:before {
    content: "\e90b";
}

.icon-icon-payment:before {
    content: "\e90c";
}

.icon-icon-close:before {
    content: "\e90a";
}

.icon-icon-card:before {
    content: "\e900";
}

.icon-icon-check:before {
    content: "\e901";
}

.icon-icon-checkout:before {
    content: "\e902";
}

.icon-icon-comment:before {
    content: "\e903";
}

.icon-icon-home:before {
    content: "\e904";
}

.icon-icon-phone:before {
    content: "\e905";
}

.icon-icon-pin:before {
    content: "\e906";
}

.icon-icon-resend:before {
    content: "\e907";
}

.icon-icon-ship:before {
    content: "\e908";
}

.icon-icon-shipping:before {
    content: "\e911";
}

.icon-icon-trash:before {
    content: "\e915";
}



@font-face {
    font-family: 'new-icomoon-file';
    src:  url('../fonts/english/new-icomoon-file.eot?jyj6ds');
    src:  url('../fonts/english/new-icomoon-file.eot?jyj6ds#iefix') format('embedded-opentype'),
      url('../fonts/english/new-icomoon-file.ttf?jyj6ds') format('truetype'),
      url('../fonts/english/new-icomoon-file.woff?jyj6ds') format('woff'),
      url('../fonts/english/new-icomoon-file.svg?jyj6ds#new-icomoon-file') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="new-icons-"], [class*=" new-icons-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'new-icomoon-file' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .new-icons-address:before {
    content: "\e701";
  }
  .new-icons-shopping-bag:before {
    content: "\e702";
  }
  .new-icons-otp-send:before {
    content: "\e703";
  }
  .new-icons-payment-icon:before {
    content: "\e704";
  }
  .new-icons-phone-verification:before {
    content: "\e705";
  }
  .new-icons-shipping-icon:before {
    content: "\e706";
  }
  .new-icons-Shopping-Cart:before {
    content: "\e710";
  }

  .new-svg-icons {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  .new-svg-icons-address {
    width: 1.1064453125em;
  }