@media (min-width:180px) and (max-width:480px)
{
    body
    {
        overflow-x: hidden !important;
    }
    .customGmapWrapper
    {
        max-width: 411px;
        margin:24px 24px 0 24px !important;
    }
    .FullScreenGmapWrapper {
        max-width: 1411px;
        .gm-bundled-control {
            margin: 4px !important;
            // bottom: 20vh!important;
            bottom: calc(24vh) !important;
        }
        .GmapContainerElement {
            height: calc(82.65vh) !important;
        }
    }
    #ribbionParent {
        .mapRibbion {
            p {
                font-size: 15px !important;
            }
        }
    }
    .FullScreenAddressWrapper
    {
        width: 95%;
        .address-pin-container
        {
            margin: 14px 0px 0 0;
            width: 100%;
        }
    }
    .bottomDialog{
        margin-left: 10px;
    }
}

@media only screen and (max-width: 500px){

    .OtherPopup {
        width: 370px !important;

        .otherPopupInv {
            .MuiTypography-h6 {
                font-size: 22px !important;
            }
            .MuiDialogContentText-root {
                font-size: 12px !important;
            }
            .dropdownAccordion .card-body {
                font-size: 10px;
            }
            .bottomDialog .MuiDialogContent-root .MuiTypography-root {
                font-size: 10px !important;
            }
        }
    }
    
    .languageMainPopup
    {
        width: 95% !important;
    }
    .languageMainPopup .otherPopupInv .dropdownAccordion .customRadio .card .card-header
    {
        padding:7px 5px;
    }
    .languageMainPopup .otherPopupInv .dropdownAccordion .customRadio .card .card-header .LangName
    {
        font-size:14px;
    }
    .languageMainPopup .otherPopupInv .dropdownAccordion .customRadio .card .card-header .LangCountry
    {
        font-size: 11px;
    }
}