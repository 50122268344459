.GoogleMapContainer {
  height: 100%;
}

.GoogleMapContainer.blackAndWhite {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
