:root {
    --animate-duration: 800ms;
    --animate-delay: 0.9s;
}

body {
    font-family: $SF_TextRegular !important;
    color: $black-blue;
    background: #fff;
    // overflow-y: auto;
    // -ms-overflow-style: none;
    // /* IE and Edge */
    // scrollbar-width: none;
    // /* Firefox */

    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

// html,
// body {
//     overflow-y: auto;
//     -ms-overflow-style: none;
//     /* IE and Edge */
//     scrollbar-width: none;
//     /* Firefox */

//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

button:focus {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.disableClick {
    cursor: progress !important;
    pointer-events: none;
}
a {
    outline: 0 none;
}

h1 {
    font-family: $SF_TextRegular;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.iconSpinning {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(cdnUrl("login_app/assets/images/logo/secure_logo.png")) 50% 50% no-repeat rgb(249, 249, 249) !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.bg-white {
    background: #fff !important;
}

.icon-white {
    color: #fff;
    font-weight: 900;
}

.hide {
    display: none;
}

.oneTap {
    color: RGB(var(--theme-custom-color)) !important;
    font-size: 32px !important;
    margin-left: 0 !important;
}

a:not([href]):not([tabindex]) {
    cursor: pointer;
}

.react-code-input input {
    font-family: monospace;
    border-radius: 6px;
    border: 0px solid lightgrey !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px !important;
    margin: 4px;
    padding-left: 10px;
    width: 36px;
    height: 42px;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    /* background-color: white;*/
}


.form-control {
    color: $black-blue;

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $grayCol;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $grayCol;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $grayCol;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $grayCol;
    }
}

.btn {
    font-size: 18px;
}

.btn:focus {
    box-shadow: none;
}

.btn-primary {
    background: RGB(var(--theme-custom-color));
    color: $white;
    border: solid 2px RGB(var(--theme-custom-color));
    font-size: 18px;
    padding: 11.5px;
    font-family: $SF_TextRegular;
    font-weight: normal;
    border-radius: 4.52082px;

    &:hover {
        background: var(--darker-lighten-theme-custom-color);
        border: solid 2px var(--darker-lighten-theme-custom-color);
        color: $white;
    }
    &:disabled
    {
      color: #ffffff;
      background: var(--darker-lighten-theme-custom-color);
      border-color: var(--darker-lighten-theme-custom-color);
    }

    &:active {
        background: var(--darker-lighten-theme-custom-color);
        color: $white;
        // border: solid 2px $lightblue;
    }

    &:focus,
    &.focus {
        color: #ffffff;
        background: var(--darker-lighten-theme-custom-color);
        border-color: var(--darker-lighten-theme-custom-color);
    }

    &.disabled {
        cursor: not-allowed;
        background: $grayBg;
        color: $grayCol;
        border-color: $grayBg;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #ffffff;
        background: var(--darker-lighten-theme-custom-color);
        border-color:var(--darker-lighten-theme-custom-color);
    }
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background: RGB(var(--theme-custom-color));
    border-color: RGB(var(--theme-custom-color));
}

.btn-outline-primary {
    border: solid 2px RGB(var(--theme-custom-color));
    background: $white;
    font-size: 18px;
    padding: 11.5px;
    font-family: $SF_TextRegular;
    font-weight: normal;
    border-radius: 4.52082px;
    color: RGB(var(--theme-custom-color));

    &:hover {
        background: RGB(var(--theme-custom-color));
        border: solid 2px RGB(var(--theme-custom-color));
    }

    &:active {
        background: RGB(var(--theme-custom-color));
        border: solid 2px RGB(var(--theme-custom-color));
    }

    &.disabled {
        cursor: not-allowed;
        background: none;
        color: $grayCol;
        border-color: $grayCol;
    }
}

.btn-outline-white {
    border: solid 2px $white;
    background: RGB(var(--theme-custom-color));
    font-size: 18px;
    padding: 11.5px;
    font-family: $SF_TextRegular;
    font-weight: normal;
    border-radius: 4.52082px;

    &:hover {
        background: $white;
        border: solid 2px $white;
    }

    &.disabled {
        cursor: not-allowed;
        background: none;
        color: $grayCol;
        border-color: $grayCol;
    }
}

.btn-blank {
    background: none;
}

.nHref {
    background: none;
    border: none;
    outline: 0 none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:focus {
        outline: 0 none;
    }
}

.btn-tiny {
    font-size: 10px !important;
    padding: 3px 9px !important;
}

.width150 {
    width: 150px;
}

.font17 {
    font-size: 17px;
}

.font18 {
    font-size: 18px;
}

.bSecureIcon {
    background: #000;
    width: 100%;
    font-size: 24px;
    font-family: $SF_ProDisplay;
    border: none;
    color: #fff !important;
    padding: 0;
    line-height: 50px;
    text-align: center;

    span {}

    img {
        margin-left: 7px;
        vertical-align: sub;
    }

    &.powered {
        background: $black-blue;
        font-size: 19px;

        img {
            margin-left: 5px;
            vertical-align: text-bottom;
            height: 24px;
        }
    }
}

.topStatus {
    height: 80px;
    background: var(--lighten-theme-custom-color);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            height: 34px;
            width: 34px;
            box-shadow: 0px 4px 4px rgba(19, 123, 222, 0.15);
            background: RGB(var(--theme-custom-color));
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .iconW {
                color: $white !important;
            }

            .iconT {
                color: RGB(var(--theme-custom-color)) !important;
            }
        }

        .statusText {
            font-family: $SF_ProDisplay;
            font-size: 24px;
            color: $black-blue;
            margin-left: 12px;

            &.oneTap {
                color: RGB(var(--theme-custom-color));
                font-size: 32px;
            }
        }
    }
}


.stepStatus {
    height: 80px;
    background: var(--lighten-theme-custom-color);
    display: flex;
    justify-content: center;
    align-items: center;

    .stepIcons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;

        .icon {
            height: 34px;
            width: 34px;
            box-shadow: none;
            background: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 15px;
            
            .iconW {
                color: $white !important;
            }

            .iconT {
                color: RGB(var(--theme-custom-color)) !important;
                background: none !important;
            }
        }

        .stepNo {
            color: var(--darker-lighten-theme-custom-color);
            font-size: 20px;
            font-family: $SF_TextRegular !important;
        }

        .statusText {
            display: none;
            font-size: 24px;
            font-family: $SF_ProDisplay;
            margin-left: 10px;
        }

        &.active {
            .icon {
                background: RGB(var(--theme-custom-color));
                color: white;
            }
        }

        &.activeText {

            .statusText {
                display: flex;
            }
        }
    }
}

.progressWrap {
    height: 2px;
    background: $grayBg;
    position: relative;
    overflow: hidden;

    .progressLine {
        position: absolute;
        height: 2px;
        top: 0;
        left: 0;
        background: RGB(var(--theme-custom-color));
    }
}

.form-control:focus {
    box-shadow: none;
    outline: none;
}


.formInput {
    position: relative;

    textarea {
        resize: none;
        height: auto !important;
    }

    input[required="required"] {
        box-shadow: none;
    }

    input,
    textarea {
        background: none;
        font-size: 20px;
        display: block;
        padding: 12px;
        height: 50px;
        border: solid 1px $grayCol;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: RGB(var(--theme-custom-color));
        }
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: $grayBg;
    }

    &.md-form {
        position: relative;

        label {
            color: $grayCol;
            position: absolute;
            top: .8rem;
            left: 12px;
            font-size: 20px;
            line-height: 23px;
            cursor: text;
            -webkit-transition: .2s ease-out;
            -moz-transition: .2s ease-out;
            -o-transition: .2s ease-out;
            -ms-transition: .2s ease-out;
            transition: .2s ease-out;

            &.active {
                font-size: 13px;
                background: $white;
                padding: 0 6px;
                color: RGB(var(--theme-custom-color));
                -webkit-transform: translateY(-115%);
                -moz-transform: translateY(-115%);
                -ms-transform: translateY(-115%);
                -o-transform: translateY(-115%);
                transform: translateY(-115%)
            }
        }

        .prefix {
            position: absolute;
            width: 3rem;
            font-size: 2rem;
            -webkit-transition: color .2s;
            -moz-transition: color .2s;
            -o-transition: color .2s;
            -ms-transition: color .2s;
            transition: color .2s;

            &.active {
                color: RGB(var(--theme-custom-color))
            }
        }

        .prefix~input,
        .prefix~textarea {
            margin-left: 3rem;
            width: 92%;
            width: calc(100% - 3rem)
        }

        .prefix~textarea {
            padding-top: .8rem
        }

        .prefix~label {
            margin-left: 3rem
        }

        @media only screen and (max-width:992px) {
            .prefix~input {
                width: 86%;
                width: calc(100% - 3rem)
            }
        }

        @media only screen and (max-width:600px) {
            .prefix~input {
                width: 80%;
                width: calc(100% - 3rem)
            }
        }

        // .input-group{
        //     border:1px solid #eee
        // }
        // .input-group .form-control{
        //     margin:0
        // }
        // .input-group ::-webkit-input-placeholder{
        //     padding-left:7px;
        //     padding-top:2px
        // }
        // .input-group :-moz-placeholder{
        //     padding-left:7px;
        //     padding-top:2px
        // }
        // .input-group ::-moz-placeholder{
        //     padding-left:7px;
        //     padding-top:2px
        // }
        // .input-group :-ms-input-placeholder{
        //     padding-left:7px;
        //     padding-top:2px
        // }
        .form-inline fieldset {
            margin-right: 1.5rem
        }
    }

    &.pressed {

        input,
        textarea {
            border-color: RGB(var(--theme-custom-color));

            &:focus {
                border-color: RGB(var(--theme-custom-color));
                box-shadow: none;
            }

            &:focus~label,
            &:valid~label {
                color: RGB(var(--theme-custom-color));
            }

            // &:focus ~ .bar:before {
            // }
        }

        label {
            color: RGB(var(--theme-custom-color));
        }

        .bar {
            &:before {
                content: '';
                background: RGB(var(--theme-custom-color));
            }
        }
    }

    &.success {

        input,
        textarea {
            border-color: $green;

            &:focus {
                border-color: $green;
                box-shadow: none;
            }

            &:focus~label,
            &:valid~label {
                color: $green;
            }

            // &:focus ~ .bar:before {
            // }
        }

        label {
            color: $green;
        }

        .bar {
            &:before {
                content: '';
                background: $green;
            }
        }

        .successMessage {
            font-size: 13px;
            color: $green;
        }
    }

    &.error {

        input,
        textarea {
            border-color: $pink;

            &:focus {
                border-color: $pink;
                box-shadow: none;
            }

            &:focus~label,
            &:valid~label {
                color: $pink;
            }

            // &:focus ~ .bar:before {
            // }
        }

        label {
            color: $pink;
        }

        .bar {
            &:before {
                content: '';
                background: $pink;
            }
        }

        .errorMessage {
            font-size: 13px;
            color: $pink;
        }
    }

    &.disabled {

        input,
        textarea {
            background: $grayBg;
            border: none;
            cursor: not-allowed;
        }

        label {}
    }

    &.checkedtxt {
        .form-control {
            border-color: RGB(var(--theme-custom-color));
        }

        .iconBg {
            background: RGB(var(--theme-custom-color));
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: inline-block;
            color: #fff;
            font-size: 12px;
            text-align: center;
            line-height: 27px;
            position: absolute;
            right: -12px;
            top: -12px;

            .icon-icon-check {
                &:before {
                    color: #fff;
                }
            }
        }
    }
}


.dropInput {
    .input-group {
        border-radius: 0.25rem;
        border: solid 1px $grayCol;

        .input-group-prepend {
            position: relative;
            margin-right: 0;

            &::before {
                content: " ";
                right: 0px;
                height: 24px;
                width: 1px;
                position: absolute;
                display: block;
                background: $grayCol;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .dropdown-toggle {
                &::after {
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    content: "";
                    border-top: 0.6em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                    position: relative;
                    top: 5px;
                }
            }
        }

        .btn {
            font-size: 20px;
            color: $grayCol;
        }

        .form-control {
            height: 50px;
            border: none;
            font-size: 20px;
            color: $black-blue;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .dropdown-menu {
            min-width: auto;
            padding: 0;
            margin: 0;
            font-size: 20px;
            color: $grayCol;

            .dropdown-item {
                padding: 0.25rem 1rem;
                border-bottom: solid 1px #e6e6e6;
            }
        }

        .react-custom-flag-select__select__wrapper___1R93I {
            font-family: $SF_TextRegular;

            img {
                width: auto !important;
            }
        }

        .react-custom-flag-select__select__dropdown-flag___4-6D7 {
            flex: auto !important;
            text-align: left;
            max-width: 44px;
        }

        .react-custom-flag-select__select__dropdown-name___TxNpJ {
            flex: auto !important;
            text-align: left;
            justify-content: flex-start;
        }

        .react-custom-flag-select__select__button___15AP2 {
            display: inline-block;
            font-weight: 400;
            color: $grayCol;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 9px 11px;
            font-size: 20px;
            line-height: 1.5;
            border: none;
            outline: 0 none;
            border-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:before {
                content: " ";
                right: 0px;
                height: 24px;
                width: 1px;
                position: absolute;
                display: block;
                background: #B3B3B3;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &:focus {
                border: none;
                outline: 0 none;
            }
        }

        .react-custom-flag-select__select__selector___1JJ_x {
            padding: 0;
        }

        .react-custom-flag-select__showArrow___2E92p:before {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.6em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            position: relative;
            top: 5px;
        }

        .react-custom-flag-select__select__dropdown-icon___3KppP {
            width: auto;
        }

        .react-custom-flag-select__select__options-container___1TPJG {
            min-width: 121px;
            padding: 0;
            margin: 1px 0 0 0;
            font-size: 18px;
            color: $grayCol;
            border: solid 1px $grayBg;
            max-height: 204px;
            overflow-x: auto;
            box-shadow: none;

            img {
                margin-right: 0px;
            }
        }

        .react-custom-flag-select__select__options-item___3_6R4 {
            background: #fff;
            color: $black-blue;
            border-bottom: solid 1px $grayBg;
            transition: none;
            padding: 6px 11px;

            &:hover {
                background: var(--lighten-theme-custom-color);
                border-bottom: solid 1px $grayBg;
            }

            &.react-custom-flag-select__active___2SYE9 {

                background: var(--lighten-theme-custom-color);
            }
        }

    }
}

/* select starting stylings ------------------------------*/
.selectMat {
    position: relative;
    margin-bottom: 25px;
}

.select-text {
    position: relative;
    font-family: $SF_TextRegular;
    background-color: transparent;
    width: 100%;
    padding: 10px 10px 10px 10px;
    ;
    font-size: 18px;
    border-radius: 0.25rem;
    border: solid 1px $grayCol;
    position: relative;
    color: $grayCol;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $black-blue;
    outline: 0 none;

    option {
        outline: 0 none;

        &:focus {
            border: none;
            outline: 0 none;
        }

        &:hover {
            border: none;
            outline: 0 none;
        }
    }

}

.select-text:focus {
    border-color: RGB(var(--theme-custom-color));
    outline: 0 none;
}

.selectMat:after {
    content: "";
    position: absolute;
    top: 1px;
    right: 6px;
    border: solid $grayCol;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    vertical-align: middle;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 9px;
    height: 9px;
    top: 18px;
    right: 16px;
}


/* LABEL ======================================= */
.select-label {
    color: $grayCol;
    position: absolute;
    pointer-events: none;
    top: .8rem;
    left: 12px;
    font-size: 20px;
    line-height: 23px;
    cursor: text;
    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -ms-transition: .2s ease-out;
    transition: .2s ease-out;

}

/* active state */
.select-text:focus~.select-label,
.select-text:valid~.select-label {
    font-size: 13px;
    background: #ffffff;
    padding: 0 6px;
    color: RGB(var(--theme-custom-color));
    -webkit-transform: translateY(-115%);
    -moz-transform: translateY(-115%);
    -ms-transform: translateY(-115%);
    -o-transform: translateY(-115%);
    transform: translateY(-115%)
}

.selectMat {
    position: relative;

    select {
        option {
            &:first-child {
                display: none
            }
        }
    }
}


.customRadio {
    .jcf-radio {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        overflow: hidden;
        cursor: default;
        background: #fff;
        border: none;
        margin: 0 3px 0 0;
        height: 24px;
        width: 24px;
        background: blue;
        border-radius: 100%;

        span {
            height: 7px;
            width: 15px;
            top: 58%;
            left: 44%;
            margin: -7px 0 0 -6px;
            border: 1px solid RGB(var(--theme-custom-color));
            border-width: 0 0 1px 1px;
            background: none;
            border-radius: 0 !important;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
        }

        &.jcf-checked {
            background: RGB(var(--theme-custom-color));

            span {
                border-color: $white;
            }
        }

        &.jcf-unchecked {
            border: 1px solid RGB(var(--theme-custom-color));
            display: none;

            span {
                border-color: RGB(var(--theme-custom-color));
            }
        }
    }

    .jcf-focus,
    .jcf-focus * {
        border-color: $white !important;
    }

    &.grayTick {
        .jcf-checkbox {
            border: 1px solid $grayCol;

            span {
                border-color: $grayCol;
            }

            &.jcf-checked {
                background: $grayCol;

                span {
                    border-color: $white;
                }
            }

            &.jcf-unchecked {
                border-color: $grayCol;

                span {
                    border-color: $grayCol;
                }
            }
        }
    }

    .accordion {
        position: relative;
        padding: 0px;

        .card {
            border: solid 1px $grayCol;
            border-radius: 4px;
            box-shadow: none;
            width: 100%;
            margin-bottom: 20px;

            .card-header {
                // height: 44px;
                line-height: 22px;
                padding: 12px 30px;
                font-size: 20px;
                min-height: auto;
                border-radius: 4px;
                justify-content: center;
                border: none;
                position: relative;
                cursor: pointer;
                color: $black-blue;

                .overflowText {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-bottom: 5px;
                }

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-bottom: 0;
                }

                span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 0.3px;
                    color: $mid-grey
                }

                .paymentType {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .information {
                        width: 190px;
                        text-align: left;
                        margin-left: 10px;

                        .overflowText {
                            margin-bottom: 0;
                        }
                    }
                }

                &.card-header-left {
                    display: block;
                    text-align: left;
                }
            }

            .editAddHeader {
                display: none;

                .editAdd {
                    font-size: 14px;
                    border-radius: 0px;
                    font-family: "SF Pro Text";
                    font-weight: 400;
                }
            }

            .saveAddHeader {
                display: none;

                .saveAdd {
                    font-size: 14px;
                    border-radius: 0px;
                    font-family: "SF Pro Text";
                    font-weight: 400;
                }
            }

            .card-body {
                font-size: 14px;
                line-height: 16px;
                padding: 20px 15px;
                color: $mid-grey;
                border-top: solid 1px $grayCol;

                .map {
                    margin-bottom: 20px;
                }

                .customRadio {
                    flex: 100%;
                }
            }

            &.addNew {
                border: dashed 1px #B3B3B3;

                .editAddHeader {
                    display: none;
                }

                &.activeShow {

                    border: solid 1px RGB(var(--theme-custom-color));

                    .card-header {
                        background: var(--lighten-theme-custom-color);

                        &:after {
                            content: "";
                            height: 24px;
                            width: 24px;
                            display: block;
                            background: RGB(var(--theme-custom-color));
                            border-radius: 50%;
                            position: absolute;
                            right: -12px;
                            top: -12px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            height: 7px;
                            width: 15px;
                            top: 2px;
                            right: -7px;
                            margin: -7px 0 0 -6px;
                            border: 1px solid #fff;
                            border-width: 0 0 1px 1px;
                            background: none;
                            border-radius: 0 !important;
                            transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            z-index: 1;
                        }
                    }

                    .customRadio {
                        .accordion {
                            .card {
                                .card-header {
                                    background: transparent;

                                    &:after {
                                        content: "";
                                        height: 24px;
                                        width: 24px;
                                        display: block;
                                        background: transparent;
                                        border-radius: 50%;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                    }

                                    &:before {
                                        content: "";
                                        position: absolute;
                                        height: 0px;
                                        width: 0px;
                                        top: 0px;
                                        right: 0px;
                                        margin: 0;
                                        border: 0px solid #fff;
                                        border-width: 0 0 1px 1px;
                                        background: none;
                                        border-radius: 0 !important;
                                        transform: rotate(-45deg);
                                        -moz-transform: rotate(-45deg);
                                        -webkit-transform: rotate(-45deg);
                                        -ms-transform: rotate(-45deg);
                                        -o-transform: rotate(-45deg);
                                        z-index: 1;
                                    }
                                }

                                &.activeShow {
                                    border: solid 1px RGB(var(--theme-custom-color));

                                    .card-header {
                                        background: var(--lighten-theme-custom-color);

                                        &:after {
                                            content: "";
                                            height: 24px;
                                            width: 24px;
                                            display: block;
                                            background: RGB(var(--theme-custom-color));
                                            border-radius: 50%;
                                            position: absolute;
                                            right: -12px;
                                            top: -12px;
                                        }

                                        &:before {
                                            content: "";
                                            position: absolute;
                                            height: 7px;
                                            width: 15px;
                                            top: 2px;
                                            right: -7px;
                                            margin: -7px 0 0 -6px;
                                            border: 1px solid #fff;
                                            border-width: 0 0 1px 1px;
                                            background: none;
                                            border-radius: 0 !important;
                                            transform: rotate(-45deg);
                                            -moz-transform: rotate(-45deg);
                                            -webkit-transform: rotate(-45deg);
                                            -ms-transform: rotate(-45deg);
                                            -o-transform: rotate(-45deg);
                                            z-index: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.error {
                    border: solid 1px #de132a;

                    .card-header {
                        background: var(--lighten-theme-custom-color);

                        &:after {
                            content: "";
                            height: 24px;
                            width: 24px;
                            display: block;
                            background: #de132a;
                            border-radius: 50%;
                            position: absolute;
                            right: -12px;
                            top: -12px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            height: 7px;
                            width: 15px;
                            top: 2px;
                            right: -7px;
                            margin: -7px 0 0 -6px;
                            border: 1px solid #fff;
                            border-width: 0 0 1px 1px;
                            background: none;
                            border-radius: 0 !important;
                            transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            z-index: 1;
                        }
                    }
                }
            }

            .myForm {
                display: flex;
                flex-wrap: wrap;

                .accEditSave {
                    margin-left: -15px;
                    margin-right: -15px;
                    width: calc(100% - -30px);
                    margin-top: -20px;
                    margin-bottom: 20px;
                    font-size: 14px;
                    border-radius: 0px;
                    font-family: $SF_TextRegular;
                    font-weight: 400;
                }

                .saveAddHeader {
                    flex: 1;
                    order: -1;
                }
            }

            // .collapsing {
            //     -webkit-transition: none;
            //     transition: none;
            //     display: none;
            //     overflow: auto;
            //     height: auto;
            // }
            &.activeShow {
                border: solid 1px RGB(var(--theme-custom-color));

                >.card-header {
                    background: var(--lighten-theme-custom-color);

                    &:after {
                        content: "";
                        height: 24px;
                        width: 24px;
                        display: block;
                        background: RGB(var(--theme-custom-color));
                        border-radius: 50%;
                        position: absolute;
                        right: -12px;
                        top: -12px;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        height: 7px;
                        width: 15px;
                        top: 2px;
                        right: -7px;
                        margin: -7px 0 0 -6px;
                        border: 1px solid #fff;
                        border-width: 0 0 1px 1px;
                        background: none;
                        border-radius: 0 !important;
                        transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        z-index: 1;
                    }
                }

                .editAddHeader {
                    display: flex;
                    flex: 100%;
                }

                .saveAddHeader {
                    display: none;
                    opacity: 1;
                }

                .card-body {
                    border-top: solid 1px RGB(var(--theme-custom-color));
                }

                .myForm {
                    display: flex;
                    flex-wrap: wrap;

                    .accEditSave {
                        margin-left: -15px;
                        margin-right: -15px;
                        width: calc(100% - -30px);
                        margin-top: -20px;
                        margin-bottom: 20px;
                        font-size: 14px;
                        border-radius: 0px;
                        font-family: $SF_TextRegular;
                        font-weight: 400;
                    }

                    .saveAddHeader {
                        flex: 1;
                        order: -1;
                    }
                }

                .activeCard {
                    border: solid 1px RGB(var(--theme-custom-color));

                    >.card-header {
                        background: var(--lighten-theme-custom-color);
                        color: $black-blue;

                        &:after {
                            content: "";
                            height: 24px;
                            width: 24px;
                            display: block;
                            background: RGB(var(--theme-custom-color));
                            border-radius: 50%;
                            position: absolute;
                            right: -12px;
                            top: -12px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            height: 7px;
                            width: 15px;
                            top: 2px;
                            right: -7px;
                            margin: -7px 0 0 -6px;
                            border: 1px solid #fff;
                            border-width: 0 0 1px 1px;
                            background: none;
                            border-radius: 0 !important;
                            transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            z-index: 1;
                        }
                    }
                }
            }

            &.activeButton {
                .card-text>.editAdd {
                    display: none;
                }

                .card-text>.saveAdd {
                    display: none;
                }
            }
        }
    }

    &.nooverleyText {
        .accordion {
            .card {
                .card-header {
                    line-height: 24px;

                    p {
                        display: -webkit-box;
                        -webkit-line-clamp: unset;
                        -webkit-box-orient: unset;
                        overflow: auto;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.collapse.show {
    width: 100%;
}

.activeShow .myForm .collapse.show+.saveAddHeader {
    display: block;
    transition: all ease 0.3s;
    opacity: 1;
}


.radioContainer {
    position: relative;
    padding: 0px;
    margin-bottom: 20px;

    .jcf-radio {
        position: absolute;
        right: -13px;
        top: -11px;
    }

    label {
        border: solid 1px $grayCol;
        border-radius: 4px;
        width: 100%;
    }

    .top {
        height: 44px;
        line-height: 44px;
        line-height: 18px;
        padding: 12px;
        font-size: 20px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .extraInfo {
        font-size: 14px;
        line-height: 16px;
        padding: 20px 15px;
        color: #91989C;
        display: none;
        border-top: solid 1px RGB(var(--theme-custom-color));
    }

    .jcf-label-active {
        border: solid 1px RGB(var(--theme-custom-color));

        .top {
            background: var(--lighten-theme-custom-color);
        }

        .extraInfo {
            display: block;
        }
    }

    input[name="myradio"]:checked+.clickable {
        border-color: #0055ff;
        box-shadow: 0px 0px 6px 2px #0055ff;
    }

    input[name="myradio"]:checked+.clickable .checked-box {
        display: block;
    }
}

header {
    padding: 0 10px;

    .logo_One {
        margin: 8px 0;
        // max-width: 100%;
        // max-width: 45%;
        // min-height: 47px;

        img {
            max-width: 100%;
            max-height: 47px;
            height: auto;
            // margin: 0 !important;
            margin: 10px 0 !important;
        }
    }

    .container {
        max-width: 1140px;
        padding: 0 15px;
    }
}

.screen {

    .container {
        max-width: 411px;
        padding: 0 43px;

        @media only screen and (max-width: 378px) {
            padding: 0 25px;
        }
    }

    .payWith {
        position: sticky;
        bottom: 43px;
        width: 325px;
        left: 43px;
        right: 43px;
        margin: auto;
    }
}

.screenFrame {
    max-width: 411px;
    margin: auto;

    img {
        max-width: 100%;
    }
}


.signInView {
    text-align: center;

    h1 {
        font-family: $SF_ProDisplay;
        color: $black-blue;
        font-size: 48px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .signviewPtext {
        font-size: 20px;
        margin-bottom: 25px;
        color: $mid-grey;
    }

    .signviewsmalltext {
        font-size: 14px;
        margin-bottom: 30px;
        color: $mid-grey;

        span {
            color: $black-blue;
        }
    }

    .Otptext {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 30px;
        color: $mid-grey;
        max-width: 230px;
        margin-left: auto;
        margin-right: auto;

        .num {
            color: $black-blue;
        }

        a {
            color: $black-blue;
        }
    }

    .dropInput {
        margin-bottom: 25px;
    }

    .notCode {
        font-size: 14px;
        font-weight: 300;
        color: $mid-grey;
        margin-bottom: 25px;

        a {
            color: $black-blue;
            text-decoration: underline;
        }
    }

    .myForm {
        font-family: $SF_TextRegular;

        .formInput {
            margin-bottom: 25px;
        }
    }

    .optionBtn {
        margin-top: 35px;
        margin-bottom: 40px;
    }

    .singleBtn {
        margin-top: 35px;
        margin-bottom: 40px;
    }

    .address-Btn {
        margin-top: 0px !important;
    }

    .continueBtn {
        .btn {
            margin-bottom: 15px;
        }
    }

    .oneTapHeading {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .ContNum {
        font-family: $SF_ProDisplay;
        font-size: 24px;
        color: RGB(var(--theme-custom-color));
        margin-bottom: 30px;
    }
}

.OtpNum {
    .OTPPin {
        .OTPPinNo {
            display: flex;
            position: relative;
            border: solid 1px $mid-grey;
            height: 50px;
            padding-left: 20px;
            padding-right: 19px;
            border-radius: 5px;

            >div>div {
                margin: 3px !important;
            }

            input {
                font-family: $SF_TextRegular !important;
                width: 100% !important;
                text-align: center;
                border: none !important;
                background: white;
                position: relative;
                font-size: 24px !important;
                outline: 0 none;
                box-shadow: none !important;
            }

            &:before {
                content: " ";
                width: 80%;
                height: 2px;
                position: absolute;
                left: 10%;
                right: 10%;
                background: $grayCol;
                top: 0;
                bottom: 0;
                z-index: 0;
                margin: auto;
            }
        }
    }
}

.optionBtn {
    margin-left: -10px;
    margin-right: -10px;

    .btn {
        margin: 0 10px;
    }
}

.map {
    position: relative;
    padding-top: 0 !important;

    iframe {
        border: none;
    }

    .mappinbtn {
        position: absolute;
        right: 10px;
        bottom: 20px;

        .btn-primary {
            color: #ffffff;
        }

        .icon-icon-pin {
            margin-right: 5px;
            font-size: 13px;
            vertical-align: sub;
        }
    }
}


.midWrap {
    /*height: calc(100vh - 255px);*/
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .midView {}
}

.FullScreenDialog{
    bottom: 20%;
    z-index: 101;
    position: fixed;
    left: 5%;
    right: unset !important;
    width: 90%;
    .overflow-scroll{
      max-height: 250px;
      overflow: scroll;
    }
    .dialog {
      margin:auto;
      border-radius: 10px;
      left: 0px;
      bottom: 0;
      position: relative;
      width: 100%;
      padding: 5px 10px !important;
      -moz-box-sizing: border-box;
      outline: none;
      background: $white;
      max-width: 370px;
      text-align: center;
      .title{
        font-size: 1.1rem;
        font-weight: bold;
      }
      .btn-ref{
        margin-top: 8px;
        font-weight: 600;
        font-size: 1rem;
        color: RGB(var(--theme-custom-color));
        @media (hover: hover) {
          &:hover {
            font-size: 1.05rem;
            cursor: pointer;
            color: var(--darker-lighten-theme-custom-color);
          }
        }
      }
    }
}

.FullScreenLocationBtnWrapper
{
    z-index: 101;
    position: fixed;
    left: 5%;
    bottom: 5%;
    right: unset !important;
    width: 90%;
    
    .confirm-location
    {
        
        button 
        {
            border-radius: 6px;
            left: 0px;
            bottom: 0;
            position: relative;
            width: 100%;
            padding: 5px 10px !important;
            -moz-box-sizing: border-box;
            outline: none;
            background: RGB(var(--theme-custom-color));
            font-size: 18px;
            border:0;
            max-width: 370px;
            &:hover
            {
                outline: 0 none;
                border:0;
                background: var(--darker-lighten-theme-custom-color);
                color: $white;
                
            }
            &:focus
            {
                background: var(--darker-lighten-theme-custom-color);
                outline: 0 none;
                border:0;
            }
        }
    }
}

.FullScreenAddressWrapper{
    // z-index: 1000000000001;
    z-index: 101;
    position: absolute;
    left: 0px;
    top: 0px;
    right:unset !important;
    
    .address-pin-container
    {
        margin: 10px 10px 0 0;
        border-radius: 6px;
        left: 10px;
        position: relative;
        width: 300px;
        padding:5px 10px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        outline: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        font-family: Roboto;

        &.mobile-screen{
            width: 100% !important;
        }
        .map-box
        {
            width: 100%;
            padding: 0;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            background: #fff;
            border-radius: 8px;
            box-sizing: border-box;
            border-bottom: 1px solid transparent;
            transition-property: background,box-shadow;
            transition-duration: 0.3s;
            form
            {
                margin:0;
                height:auto;
                display:flex;
                width: 80%;
            }
            .MuiFormControl-root
            {
                display:flex;
            }
            #map-address
            {
                border: 0;
                padding: 0 8px;
                min-height: 40px;
                color: $darkGrayCol;
            }
            fieldset
            {
                padding-left: 0px;
                border: 0;
                padding: 0;
                height: auto;
            }
            .formRightSide
            {
                display: flex;
                width: 20%;
                justify-content: flex-end;
                button
                {
                    border:0;
                    background: none;
                    // margin: 0 12px 0 0;
                    margin: 0 9px 0 0;
                    position: relative;
                    padding:0;
                    &:active
                    {
                        outline: 0 0 0;
                        box-shadow: 0 0 0;
                    }
                    &:focus
                    {
                        outline: 0 0 0;
                        box-shadow: 0 0 0;
                    }
                    svg
                    {
                        color: $grayCol;
                    }
                    img
                    {
                        // width: 20px;
                        // height:20px;
                    }
                    &:last-child
                    {
                        padding-left: 7px;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        &:before
                        {
                            border-left: 1px solid #ccc;
                            content: '';
                            width: 2px;
                            height: 24px;
                            position: absolute;
                            left: 0;
                        }
                    }
                    &.mapDirection
                    {
                        cursor: pointer;
                        span{
                            display: flex;
                            flex-direction: row;
                        }
                        svg
                        {
                            fill: RGB(var(--theme-custom-color));
                            width: 24px;
                            height:24px;
                            path
                            {
                                fill:#fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.termCondition {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $black-blue;
    text-align: left;
    margin: 30px 0;

    a {
        color: RGB(var(--theme-custom-color));
    }

    button {
        border: 0;
        color: RGB(var(--theme-custom-color));
        padding: 0;
        margin: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        text-transform: unset;

        &:hover {
            background: none;
            color: RGB(var(--theme-custom-color));
            border: 0;
            text-decoration: underline;
        }

        &:focus {
            background: none;
            color: RGB(var(--theme-custom-color));
            border: 0;
            text-decoration: underline;
            box-shadow: 0 0 0;
            outline: none;
        }
    }
}

.headerDropdown {
    margin: 0px 0 0 0;

    .nav-link {
        font-size: 14px;
        white-space: unset;
        width: auto;
        overflow: auto;
        text-overflow: initial;
        margin-top: 11px;
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        text-transform: uppercase;
        color: RGB(var(--theme-custom-color)) !important;
        font-family: SF Pro Text;

        .avatar {
            display: none;
        }

        .text-default {
            font-size: 14px;
            // white-space: nowrap;
            // width: 95px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            white-space: unset;
            width: auto;
            overflow: auto;
            text-overflow: initial;
            margin-top: 14px;
            padding-left: 0;
            padding-right: 0;
            font-weight: 700;
            text-transform: uppercase;
            color: RGB(var(--theme-custom-color)) !important;
            font-family: SF Pro Text;

            &::before {
                display: inline-block;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-bottom: 0;
                border-left: .3em solid transparent;
                margin-right: 6.47px;
            }
        }
    }

    // @media only screen and (min-width: 450px) {
    //     .nav-link {
    //         white-space: unset;
    //         width: auto;
    //         overflow: auto;
    //         text-overflow: initial;

    //         .text-default {
    //             white-space: unset;
    //         width: auto;
    //         overflow: auto;
    //         text-overflow: initial;
    //         }
    //     }
    // }
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 25px !important;
}

.MuiFormControl-root {
    margin-bottom: 25px !important;
}

.msgfieldBot {
    margin-bottom: 0px !important;
}

.MuiFormLabel-root.Mui-focused {
    color: RGB(var(--theme-custom-color)) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: RGB(var(--theme-custom-color)) !important;
    border-width: 1px !important;
}

.MuiInputBase-input {
    font-family: $SF_TextRegular;
}

// .Mui-disabled {
//     background: $grayBg;
// }

.MuiDialog-scrollPaper {
    align-items: flex-end !important;
}

.MuiDialog-paperWidthSm {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0px !important;
    max-width: 100% !important;
    background: RGB(var(--theme-custom-color)) !important;
    color: #fff !important;
    text-align: center;
    &.TermsPopup, .PrivacyPopup{
        text-align: left;
        max-height: 350px;
    }
}

.MuiTypography-h6 {
    font-size: 24px !important;
    font-family: $SF_ProDisplay !important;
    font-weight: 500;
    line-height: 28px !important;
    letter-spacing: 0.0075em;
}

.MuiTypography-body1 {
    font-weight: 300 !important;
    font-size: 14px !important;
    font-family: $SF_TextRegular !important;
    color: #fff !important;
}

.MuiDialogContent-root {
    overflow: auto !important;
    margin-bottom: 10px;
}

.MuiDialogTitle-root {
    padding: 25px 24px 10px !important;
}

.MuiDialogContentText-root {
    margin-bottom: 0 !important;

}

.MuiSelect-select:focus {
    background: none !important;
    outline: 0 none !important;
}

.closeright {
    position: absolute !important;
    right: 0;
    top: 0;
    color: white !important;

    &:hover {
        border: none !important;
    }
}

.MuiInputBase-formControl {
    .MuiSelect-iconOutlined {
        background: url(cdnUrl("assets/icon-caret-down.png")) 50% 50% no-repeat;

        path {
            display: none !important;
        }
    }
}


.MuiDialog-paperScrollPaper {

    .MuiPickersCalendarHeader-switchHeader {
        .MuiTypography-body1 {
            font-size: 14px !important;
        }
    }

    .MuiPickersCalendarHeader-dayLabel {
        color: #fff !important;
    }

    .MuiPickersDay-day {
        color: #fff !important;
    }

    .MuiPickersCalendarHeader-switchHeader {
        margin-bottom: 20px;
    }
}

.MuiDialog-paper {
    .MuiDialogActions-root {
        .MuiButton-textPrimary {
            &:first-child {
                color: $white;
                border: solid 1px $white;
            }

            &:last-child {
                background: $white;
                border: solid 1px $white;
            }
        }
    }
}


.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn.btn-white.btn-white-B.MuiButton-textPrimary {
    color: RGB(var(--theme-custom-color));
    padding: 6px 16px;
}

.checkBoxCard {
    margin: 12px 0 0 0;
    text-align: left;

    .Mui-disabled {
        background: transparent;

        .MuiFormControlLabel-label {
            color: #5F666A;
        }

        .MuiIconButton-colorPrimary {
            .MuiIconButton-label {
                svg {
                    fill: #B3B3B3;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .MuiIconButton-colorPrimary {
        .MuiIconButton-label {
            svg {
                fill: RGB(var(--theme-custom-color));
                width: 24px;
                height: 24px;
            }
        }
    }

    .MuiFormControlLabel-label {
        font-family: $SF_TextRegular;
        font-style: normal;
        font-weight: 300;
        font-size: 16px !important;
        line-height: 20px !important;
        display: flex;
        align-items: center;
        color: #192631 !important;
    }
}

@media only screen and (max-width: 410px) {
    .signInView {
        h1 {
            font-size: 36px;
            margin-top: 30px;
        }

        .signviewPtext {
            font-size: 16px;
        }

        .signviewsmalltext {
            font-size: 11px;
        }

        .notCode {
            font-size: 12px;
        }

        .width150 {
            width: 123px;
        }

        .btn-outline-primary {
            font-size: 14px;
        }

        .btn-primary {
            font-size: 14px;
        }

        .dropInput {
            .input-group {
                .react-custom-flag-select__select__button___15AP2 {
                    font-size: 16px;
                    padding: 11px;
                }

                .form-control {
                    font-size: 16px;
                }
            }
        }
    }

    .oneTap {
        font-size: 24px !important;
    }

    .topStatus {
        height: 65px;

        a {
            .icon {
                height: 28px;
                width: 28px;
            }

            .statusText {
                font-size: 16px;
            }
        }
    }

    .font18 {
        font-size: 14px;
    }

    .stepStatus {
        height: 65px;

        .stepIcons {
            margin: 0 7px;

            .icon {
                width: 28px;
                height: 28px;
                font-size: 13px;
            }

            .statusText {
                font-size: 16px;
            }
        }
    }
    .termCondition {
        font-size: 12px;

        button {
            font-size: 12px;
        }
    }

    .customRadio {
        .accordion {
            .card {
                .card-header {
                    font-size: 15px;
                }

                .card-body {
                    font-size: 12px;
                }
            }
        }
    }

    .screen {

        .payWith {
            width: 285px;
        }
    }
}



.zero-padding {
    padding: 0px;
}

.MuiDialogActions-root {
    justify-content: center !important;

    .btn-white {
        color: RGB(var(--theme-custom-color));
        background-color: $white;
        border: solid 1px $white;
        font-size: 13px;

        &:hover,
        &:focus {
            color: RGB(var(--theme-custom-color));
            background-color: $white;
            border: solid 1px $white;
        }
    }

    .btn-outline-white {
        border: solid 1px $white;
        background: RGB(var(--theme-custom-color));
        color: $white;
        font-size: 13px;

        &:hover {
            background: $white;
            border: solid 1px $white;
            color: RGB(var(--theme-custom-color));
        }

        &.disabled {
            cursor: not-allowed;
            background: none;
            color: $grayCol;
            border-color: $grayCol;
        }
    }
}

.MuiPickersBasePicker-container {
    justify-content: center;
    align-items: center;
}

.MuiDialogContent-root {
    margin-top: 10px;
}

.MuiInputBase-root.Mui-disabled {
    // .MuiOutlinedInput-notchedOutline {
    //     border: none;
    // }

    .MuiInputBase-input.Mui-disabled {
        cursor: not-allowed;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

// .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
//     display: none !important;
// }

.MuiFormLabel-root.Mui-disabled {
    // display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.newCustomRadio {
    .card-text {
        margin: 0;
    }
}

.newContinueBtnWrap {
    button {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.MuiDialog-paper .MuiDialogActions-root .MuiButton-textPrimary:first-child:hover {
    color: RGB(var(--theme-custom-color)) !important;
}


.mtop20 {
    margin-top: 20px !important;
}

.processingWrap {
    height: 99vh;
}

.react-code-input {
    width: 100%;
}

.errorPage {
    background: var(--lighten-theme-custom-color);
    height: calc(100vh - 3px);
    display: flex;

    .errorPageLogo {
        background: var(--lighten-theme-custom-color);
        background: -moz-linear-gradient(left, #ebf2f9 19.14%, #c6d7eb 154.68%);
        background: -webkit-linear-gradient(left, #ebf2f9 19.14%, #c6d7eb 154.68%);
        background: linear-gradient(to right, #ebf2f9 19.14%, #c6d7eb 154.68%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf2f9', endColorstr='#c6d7eb', GradientType=1);
        box-shadow: 25.6842px 6.42105px 102.737px rgba(18, 61, 101, 0.3), inset 1.8421px 1.63158px 18.421px rgba(255, 255, 255, 0.4);
        width: 226px;
        height: 100%;
        margin-left: 160px;
        position: relative;

        img {
            width: 100px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        @media only screen and (max-width: 768px) {
            width: 194px;
            margin-left: 40px;

            img {
                width: 80px;
            }
        }

        @media only screen and (max-width: 600px) {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            margin-bottom: 30px;
            height: 100px;
            border-radius: 20px;

            img {
                width: 60px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
    }

    .errorPageText {
        margin: auto 60px;

        h1 {
            font-size: 50px;
            font-family: $SF_ProDisplay;
            color: RGB(var(--theme-custom-color));
            margin-bottom: 8px;
        }

        p {
            font-size: 34px;
            font-family: $SF_ProDisplay;
            margin-bottom: 15px;
            line-height: 40px;
        }

        .btn-error-back {
            background: var(--darken-lighten-theme-custom-color);
            background: -moz-linear-gradient(left, #ebf2f9 19.14%, #c6d7eb 154.68%);
            background: -webkit-linear-gradient(left, #ebf2f9 19.14%, #c6d7eb 154.68%);
            background: linear-gradient(to right, #ebf2f9 19.14%, #c6d7eb 154.68%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf2f9', endColorstr='#c6d7eb', GradientType=1);
            box-shadow: -25.6842px -25.6842px 64.2105px rgba(255, 255, 255, 0.8),
                25.6842px 6.42105px 102.737px rgba(18, 61, 101, 0.3),
                inset -12.8421px -9.63158px 128.421px rgba(255, 255, 255, 0.18);
            border-radius: 60px;
            width: 145px;
            padding: 4px 15px;
        }

        @media only screen and (max-width: 600px) {
            margin: 0px auto;
            width: 70%;

            h1 {
                font-size: 36px;
            }

            p {
                font-size: 24px;
                line-height: 26px;
            }

            .btn-error-back {
                border-radius: 50px;
                width: 130px;
                font-size: 14px;
            }
        }
    }

    // .errorPageInner {
    //     position: absolute;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;

    //     .bgTrack {
    //         background: url("../../assets/images/trackbg.png") no-repeat;
    //         width: 412px;
    //         height: 241px;
    //         position: absolute;
    //         top: 30%;
    //         z-index: -1;
    //         right: 0;
    //     }

    //     .dPosition {
    //         top: -120px;
    //         position: relative;
    //         left: 50px;
    //     }

    //     .subHead {
    //         font-family: $SF_ProDisplay;
    //         font-weight: 800;
    //         font-size: 19.5385px;
    //         line-height: 110.4%;

    //         /* or 22px */
    //         text-align: center;

    //         /* Primary / Blue */
    //         color: RGB(var(--theme-custom-color));
    //         -moz-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -webkit-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -o-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -ms-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         left: 53px;
    //         position: relative;
    //         top: 30px;
    //     }

    //     .Head {
    //         font-family: $SF_ProDisplay;
    //         font-weight: 800;
    //         font-size: 82.3865px;
    //         line-height: 110.4%;

    //         /* or 91px */
    //         text-align: center;

    //         /* Primary / NavyBlue */
    //         color: $navyblue;
    //         -moz-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -webkit-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -o-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         -ms-transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //         transform: scale(1) rotate(35deg) skew(-35deg, -6deg);
    //     }
    // }
}

.MuiBackdrop-root {
    background-color: var(--lighten-theme-custom-color) !important;
    // background: none !important;
}


.phone_with_ddd.Mui-error.Mui-error {
    color: #f44336;
    font-size: 1rem;
}

.dropInput .input-group.Mui-error {
    border: solid 1px #f44336;
}

.MuiAutocomplete-popper {
    height: 100px !important;
}

.MuiTooltip-tooltip {
    background: #ffffff !important;
    border: none;
    box-shadow: 0px 0px 13px 3px rgba(181, 195, 203, 1);
    width: 115px;
}

.bs-tooltip-top .arrow::before {
    border-top-color: #fff !important;
}


.OtherPopup {
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    width: 480px !important;
    margin: auto !important;
    .MuiDialog-scrollPaper {
        align-items: center !important;
    }

    .MuiDialog-paperScrollPaper {
        box-shadow: 0px 18.8925px 15.114px rgba(0, 64, 255, 0.05), 0px 23.9305px 47.8611px rgba(0, 0, 0, 0.1);
    }

    .otherPopupInv {
        background: white;

        .MuiDialogTitle-root {
            padding: 22px 24px 0px !important;
        }

        .MuiDialogContent-root {
            padding: 0 !important;
            margin-bottom: 15px;
        }

        .MuiTypography-h6 {
            color: $navyblue;
            font-size: 28px !important;
        }

        .MuiDialogContentText-root {
            color: RGB(var(--theme-custom-color)) !important;
            font-size: 15px !important;

            @media only screen and (max-width: 450px) {
                margin-right: 1rem;
            }
        }

        .dropdownAccordion {
            width: 70%;
            margin: auto;

            .card-body {
                padding: 0;
                font-size: 12px;
                box-shadow: 0px 12.595px 15.114px rgba(0, 64, 255, 0.05), 0px 12.595px 47.8611px rgba(0, 0, 0, 0.05);
                border-radius: 6.29751px;

                ul {
                    list-style: none;
                    text-align: left;
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 700;

                    li {
                        padding: 14px;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid $grayBg;
                        align-items: center;
                        color: $grayCol !important;
                        cursor: pointer;

                        .tickIcon {
                            position: relative;
                            display: none;

                            &:before {
                                content: "";
                                position: absolute;
                                height: 5px;
                                width: 12px;
                                top: 0px;
                                right: 3px;
                                margin: -4px 0 0 -6px;
                                border: 1px solid #fff;
                                border-width: 0 0 1px 1px;
                                background: none;
                                border-radius: 0 !important;
                                transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                -ms-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                                z-index: 1;
                            }

                            &:after {
                                content: "";
                                height: 18px;
                                width: 18px;
                                display: block;
                                background: RGB(var(--theme-custom-color));
                                border-radius: 50%;
                                position: absolute;
                                right: 0px;
                                top: -9px;
                            }
                        }

                        &.active {
                            .tickIcon {
                                display: block;
                            }
                        }
                    }
                }

                .MuiFormControl-root {
                    padding-bottom: 0;
                    margin-bottom: 0 !important;
                }

                .MuiFormControlLabel-labelPlacementStart {
                    margin-left: 0;
                    margin-right: -11px;
                    flex-direction: row-reverse;
                    border-bottom: 1px solid $grayBg;
                    margin-bottom: 0;
                    width: 100%;
                    justify-content: space-between;
                }

                .MuiTypography-body1 {
                    font-weight: 700 !important;
                    font-size: 12px !important;
                    color: $grayCol !important;
                    text-transform: uppercase;
                    margin-left: 15px;
                }
            }


            @media only screen and (max-width: 450px) {
                width: 85%;
            }
        }

        .bottomDialog {

            .MuiDialogContent-root {
                .MuiTypography-root {
                    text-transform: uppercase;
                    font-weight: 700 !important;
                    font-size: 12px !important;
                    color: $grayCol !important;

                    .icon-icon-lock {
                        margin-right: 7px;
                        color: RGB(var(--theme-custom-color));
                    }
                }
            }
        }

        .shippingCustomRadio {
            .accordion {
                .card {
                    border: none;

                    &.activeShow {
                        border: none;

                        .card-body {
                            border: none;
                        }
                    }

                    .card-header {
                        background: #FFFFFF;
                        box-shadow: 0px 12.595px 15.114px rgba(0, 64, 255, 0.05), 0px 12.595px 47.8611px rgba(0, 0, 0, 0.05);
                        border-radius: 6.29751px;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-align: left !important;
                        font-weight: 700;
                        padding: 0 18px;
                        color: RGB(var(--theme-custom-color));
                        display: flex;
                        justify-content: space-between;

                        span {

                            font-weight: 700 !important;
                            color: RGB(var(--theme-custom-color));
                        }

                        span:last-child {
                            border-width: 6px;
                            border-style: solid;
                            border-right: 6px solid transparent;
                            border-color: $grayCol transparent transparent;
                            width: 0;
                            height: 0;
                        }

                        &:after {
                            content: none;
                        }

                        &:before {
                            content: none;
                        }
                    }
                }
            }
        }

        .MuiDialogActions-root {
            flex-direction: column;
            flex-wrap: wrap;

            .MuiButton-root {
                min-width: 85%;
                font-family: $SF_ProDisplay;
                padding: 10px 8px;
                text-align: center;
            }
        }

        .MuiDialogActions-spacing> :not(:first-child) {
            margin-left: 0;
        }

        .MuiDialogActions-spacing {
            .btn-outline-white {
                border: solid 1px RGB(var(--theme-custom-color)) !important;
                margin-bottom: 5px;
            }
        }
    }


    .headerDropdown {
        .nav-link {
            color: #ffffff !important;

            .text-default {
                color: #ffffff !important;
                font-size: 12px;
                white-space: nowrap;
                width: 82px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }
}

.languageIcon {
    .nav-link {
        padding-right: 4px;
        color: #192631;
        font-weight: 600;

        @media only screen and (max-width: 550px) {
            font-size: 20px;
            margin-bottom: 4px;
        }
    }

    // i {
    //     font-size: 24px;
    //     color: #E6E6E6;
    //     margin-top: 2px;
    // }
}

.headerDropdown {
    padding-right: 10px;
}





.languageMainPopup {
    .MuiBackdrop-root 
    {
        background-color: var(--lighten-theme-custom-color) !important;
    }
    .MuiDialog-paper {
        overflow: hidden;
        background: transparent !important;
        border: 0;
        box-shadow: 0 0 0;

        .langCloseIcon {
            padding: 0 !important;
            width: auto;
            position: absolute;
            top: 0;
            z-index: 99999999;
            width: 28px;
            right: 0px;

            button {
                background: RGB(var(--theme-custom-color));
                padding: 4px;

                svg {
                    fill: #fff;
                    font-size: 20px;
                }

                &:hover {
                    background: RGB(var(--theme-custom-color)); 
                }

                &:active {
                    background: RGB(var(--theme-custom-color));
                    color: $white;
                    border: solid 0px RGB(var(--theme-custom-color));
                }

                &:focus,
                &.focus {
                    color: #ffffff;
                    background: RGB(var(--theme-custom-color));
                    border-color: transparent;
                }
            }
        }

        .insidelangPopup {
            @include border-radius(10px);
            background: #fff;
            width: 96%;
            margin: 3% 2%;
            padding: 30px 21px;
        }

        .otherPopupInv {
            overflow-y: auto;
            text-align: left;
            @include border-radius(10px);

            &::-webkit-scrollbar {
                width: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 6px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #E6E6E6;
                width: 1px;
                border-radius: 6px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #E6E6E6;
                border-radius: 6px;
            }

            .MuiDialogContent-root {
                margin-top: 0;
            }
        }
    }

    .suggestedHeadingText {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: RGB(var(--theme-custom-color));
        margin-bottom: 15px;
        display: block;
    }

    .otherPopupInv {
        .dropdownAccordion {
            width: 100%;
            margin: unset;

            .customRadio {
                display: flex;
                width: 100%;
                flex-wrap: wrap;

                .card {
                    width: 33.33%;
                    margin-bottom: 10px;
                    flex-wrap: wrap;
                    padding-right: 10px;
                    box-shadow: 0 0 0;
                    border: 0;

                    .card-header {
                        background-color: #fff !important;
                        background: none !important;
                        border: 1px solid #fff;
                        box-shadow: 0 0 0 !important;
                        padding: 7px 13px;
                        flex-direction: column;
                        border-bottom: 0;
                        min-height: unset;
                        cursor: pointer;
                        min-height: 54px;

                        .LangName {
                            font-style: normal;
                            font-weight: normal !important;
                            font-size: 15px;
                            line-height: 20px;
                            text-transform: capitalize;
                            color: #192631;
                            width: 100% !important;
                            margin-bottom: 0;
                        }

                        .LangCountry {
                            font-style: normal !important;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 18px;
                            text-transform: capitalize;
                            color: #192631;
                            width: 100% !important;
                            margin-bottom: 0;
                        }

                        &:hover {
                            /* Blue / Tints / Pressed Secondary */
                            background: #EBF5FF !important;
                            /* Primary / Blue */
                            border: 0.196481px solid RGB(var(--theme-custom-color));
                            box-sizing: border-box;
                            box-shadow: 0px 0.785924px 1.96481px rgba(0, 0, 0, 0.25) !important;
                            border-radius: 1.96481px !important;
                        }

                    }

                    &.activeShow {
                        .card-header {
                            /* Blue / Tints / Pressed Secondary */
                            background: var(--lighten-theme-custom-color) !important;
                            /* Primary / Blue */
                            border: 0.196481px solid RGB(var(--theme-custom-color));
                            box-sizing: border-box;
                            box-shadow: 0px 0.785924px 1.96481px rgba(0, 0, 0, 0.25) !important;
                            border-radius: 1.96481px !important;

                            &:before {
                                content: "";
                                position: absolute;
                                height: 4px;
                                width: 7px;
                                top: -1px;
                                right: 9px;
                                margin: 0;
                                border: 1px solid #fff;
                                border-width: 0 0 1px 1px;
                                background: none;
                                border-radius: 0 !important;
                                transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                -ms-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                                z-index: 1;
                            }

                            &:after {
                                content: "";
                                height: 12px;
                                width: 12px;
                                display: block;
                                background: RGB(var(--theme-custom-color));
                                border-radius: 50%;
                                position: absolute;
                                right: 6px;
                                top: -4px;
                            }
                        }
                    }

                    @media only screen and (max-width: 450px) {
                        width: 60.33%;
                    }

                    @media only screen and (max-width: 767px) {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }

        }

        .MuiDialogContent-root {
            margin-bottom: 0;
        }
    }

    .MuiBackdrop-root {
        background-color: rgba(19, 123, 222, 0.15) !important;
    }
}

.mainPermissionPopWrap {
    align-items: center;

    .permissionPopLang {
        .nav-link {
            margin: auto !important;
            font-size: 20px;
        }
    }
}

.customHeaderThings {
    .dropdown {
        max-width: 130px;
        display: flex;

        .text-default {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: initial;
        }
    }
}

.customGmapWrapper {
    max-width: 411px;
    margin: 24px auto 0 !important;
}

#mandatoryPin {
    z-index: 0 !important;
    // z-index: 10000001 !important;
    width: 100% !important;
    height: 28px !important;
    background: $black-blue;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    backdrop-filter: blur(157.781px);
    bottom: 0 !important;

    .pinStripe {
        background: transparent !important;
        width: 100% !important;
        right: 0px;
        top: 0px;
        backdrop-filter: blur(157.781px);

        p {
            z-index: 10000001 !important;
            font-family: "SF Pro Text";
            font-style: normal;
            text-align: left;
            color: #ffffff;
            margin: 8px 20px;
            font-weight: 400;
            font-size: 12.757px;
            line-height: 13px;
        }
    }
}

#ribbionParent {
    // z-index: 10000001 !important;
    width: 100% !important;
    height: 65px !important;
    background: rgba(235, 245, 255, 1);
    z-index: 9999;
    display: flex;
    flex-direction: row;
    backdrop-filter: blur(157.781px);
    bottom: 0 !important;

    .mappinbtn {
        z-index: 10000001 !important;
        right: 0;
        margin-left: auto;
        
        button {
            position: relative;
            top: 9px;
            z-index: 10000001!important;
            background: none #fff;
            border: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 30px !important;
            height: 27px !important;
            border-radius: 15px;
            overflow: hidden;
            margin: 0 10px!important;
            // box-shadow: 0 0.91872px 0.91872px rgb(0 0 0 / 25%);
            img{
                z-index: 10000001!important;
                width: 100%;
            }
        }
    }
}

.fs-container {
    .row {
        margin: 0;

        .col-sm-12 {
            padding: 0;
        }
    }

    .FullScreenGmapWrapper {
        // max-width: 1411px;
        max-width: 100% !important;
        position: relative;

        .gm-bundled-control {
            margin: 4px !important;
        }
        .GmapContainerElement {
            // height: calc(100vh - 175px) !important;
            // height: calc(79vh) !important;
            height: calc(76.65vh) !important;
            position: absolute;
            width: 100%;
            overflow: hidden !important;

            .GmapMapElement {
                border-radius: 0;
            }
        }
        #fullScreenToggleBtn {
            display:none;
         }
         #ribbionParent{
             display:none;
         }
        // .mapRibbion{
        //     p {
        //         margin: 0px 7px;
        //         line-height: 45px;
        //     }
        // }

        // .mappinbtn{
        //     button {
        //         top: 8px;
        //     }
        // }
    }
}


.GmapWrapper {
    max-width: 411px;
    margin: 24px auto 0 !important;
}

.GmapLoadingElement {
    height: 100% !important;
}

.GmapWrapper {
    max-width: 411px;
    margin: 24px auto 0 !important;
    .gm-bundled-control {
        display:none
    }
}

.GmapLoadingElement {
    height: 100% !important;
}

.GmapContainerElement {
    height: 280px !important;
}

.GmapMapElement {
    height: 100% !important;
    @include border-radius(9.47205px);
}

#mapTopLayer{
    width: 100%;
    .fullscreen-control {
        z-index: 10001;
        background: none rgb(255, 255, 255);
        border: 0px;
        margin: 0px 10px;
        padding: 0px;
        text-transform: none;
        appearance: none;
        position: absolute;
        cursor: pointer;
        user-select: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        overflow: hidden;
        right: -4px;
        top: 10px;
        width: 30px !important;
        height: 30px !important;
        border-radius: 10px;
        &.fullscreen-location-pin-control{
            top: 0px !important;
        }
        img {
            height: 30px;
            width: 20px;
        }
    }
}

.mapRibbion {
    z-index: 10000001 !important;
    position: absolute;
    width: 100%;
    height: 45px;
    right: 0px;
    top: 0px;
    background: #d2aaff29;
    // background: #9c98ab4d;
    -webkit-backdrop-filter: blur(157.781px);
    backdrop-filter: blur(157.781px);
    p {
        z-index: 10000001 !important;
        font-family: $SF_TextRegular;
        font-style: normal;
        line-height: 13px;
        text-align: left;
        color: $black-blue;
        margin: 15px 7px;
        font-weight: 400;
        font-size: 13.757px;
        line-height: 13px;
        font-weight: 600;
    }
    button {
        position: relative;
        top: 4px;
        z-index: 10000001 !important;
        background: none white;
        border: 0px;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        width: 30px !important;
        height: 30px !important;
        border-radius: 15px;
        overflow: hidden;
        margin: 0px 10px !important;
        box-shadow: 0px 0.91872px 0.91872px rgba(0, 0, 0, 0.25);
        img {
            z-index: 10000001 !important;
            width: 100%;
        }
    }
}

.btn-circle {
    font-size: 10px !important;
    padding: 3px 9px !important;
    border-radius: 50px !important;
}

.btn-tiny {
    font-size: 10px !important;
    padding: 3px 9px !important;
}

/* HOME SCREEN*/


/* generated by https://loading.io/ */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    order: 1;
    display: flex;   
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #1365db;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.home-loading
{
    position: relative;
    z-index: 9999999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    height: calc(99vh - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-heading
{
    z-index: 9999999;
    height: auto;
    width: 100%;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 88.4%;
    text-align: center;
    color: $black-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    margin: 0
}

.home-loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.3);
}

.home-loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
}

/*LOADER CSS*/
.loading {
    position: fixed;
    z-index: 9999999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
}


.spinner {
     position: absolute;
    /* left: 50%;
      top: 50%; */
    height: 50px;
    width: 50px;
    margin: 0px auto;
    border-left: 6px solid var(--darker-lighten-theme-custom-color);
    border-right: 6px solid var(--darker-lighten-theme-custom-color);
    border-bottom: 6px solid var(--darker-lighten-theme-custom-color);
    border-top: 6px solid RGB(var(--theme-custom-color));
    border-radius: 100%;
    -webkit-animation:rotate 0.6s linear infinite;
    -moz-animation:rotate 0.6s linear infinite;
    animation:rotate 0.6s linear infinite;
}

    
@-moz-keyframes rotate { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }}

#retry-otp{
    cursor:pointer;
}

.footerWrap
{
    padding: 0;
    position: fixed;
    text-align: center;
    margin: auto;
    width: 100%;
    bottom: 30px;
    .container
    {
        max-width: 411px;
        margin:auto;
        padding:0 15px;
        justify-content: center;
        display: flex;
        .footer-main
        {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            img
            {
                cursor: pointer;
            }
            ul
            {
                display: inline;
                border-left:1px solid $light-grey;
                margin:0 0 0 19px;
                padding:0 0 0 12px;
                li
                {
                    display: inline-block;
                    list-style: none;
                    span
                    {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 12px;
                        font-family: $SF_TextRegular;
                        color: $mid-grey;
                        margin-right:19px;
                        display: block;
                    }
                    &:last-child
                    {
                        span
                        {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    &.footer-transition{
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 1s, opacity 1s linear;
    }
}

#privacyDialogContent{
    text-align: left;
    .see_more{
        text-decoration: underline;
        cursor: pointer;
    }
}

.inline-items{
    display: flex;
    justify-content: space-between;
}
.inline-items > div{
    width: 48%;
}

.browSetting {
    text-align: left;
    max-width: 770px;
    margin: auto;
    font-size: 9.55556px;
    line-height: 1.25;
  
    ul {
      list-style-type: decimal;
      padding-left: 17px;
  
      ul {
        list-style-type: disc;
      }
    }
  }