body[dir="rtl"] {

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  ol,
  ul,
  blockquote,
  q,
  table,
  .btn-primary,
  .btn-outline-primary,
  .btn-outline-white,
  .stepNo,
  .react-custom-flag-select__select__wrapper___1R93I,
  .select-text,
  .accEditSave,
  .myForm,
  input,
  .MuiInputBase-input,
  .MuiTypography-body1,
  .MuiFormControlLabel-label,
  .bSecureIcon,
  .statusText,
  .ContNum,
  .totalPrice,
  .MuiTypography-h6,
  .MuiButton-root {
    font-family: $Scheherazade !important;
  }

  .MuiTypography-gutterBottom {
    margin-bottom: 1.35em;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.btn.btn-white.btn-white-B.MuiButton-textPrimary {
    margin-left: 20px;
  }

  header {
    font-family: $Scheherazade !important;

    span {
      font-family: $Scheherazade !important;
    }
  }

  .stepStatus {
    direction: rtl;
    /*height: 65px;*/

    .activeText {
      .statusText {
        font-size: 30px;
        margin-left: unset;
        margin-right: 10px;
      }
    }

    .statusText {
      font-size: 30px;
    }

  }

  .topStatus span .statusText {
    font-size: 28px;
  }

  .alert {
    font-size: 1.15rem;
  }

  .signInView {
    h1 {
      font-family: $Scheherazade !important;
      font-size: 3rem;
    }

    .ContNum {
      font-size: 2rem;
    }

    .signviewPtext {
      font-size: 18px;
    }

    .continueBtn {
      .btn {
        margin-bottom: 15px;
        font-size: 20px;
      }
    }

    .signviewsmalltext {
      font-size: 20px;
    }

    .Otptext {
      font-size: 18px;
      line-height: 23px;
    }

    .dropInput {
      .input-group {
        .form-control {
          font-size: 18px;
        }
      }
    }
  }

  .statusText {
    font-family: $Scheherazade !important;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    font-family: "icomoon" !important;
  }

  [class^="new-icons-"],
  [class*=" new-icons-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'new-icomoon-file' !important;

  }

  input {
    text-align: right;
  }

  /*.PrivateNotchedOutline-legendLabelled-3
    {
        text-align: right;
    }
    .MuiInputLabel-formControl
    {
        text-align: right;
    }
    .MuiInputBase-input
    {
        text-align: right;
        &:-moz-placeholder {
            text-align: right;
        }
        &:-ms-input-placeholder {
            text-align: right;
        }
        &::-webkit-input-placeholder {
            text-align: right;
        }
    }*/
  .checkBoxCard {
    margin-left: auto;
    text-align: right;

    label {
      margin-right: 0;
      direction: rtl;

      span {
        font-size: 19px !important;

        &:first-child {
          order: 1;
          padding-right: 0;
        }

        &:last-child {
          order: 2;
          text-align: right;
        }
      }
    }
  }

  .termCondition {
    text-align: right;
  }

  .checkOutInfoBox {
    .iconCheckout {
      order: 2;
      text-align: right;
    }

    .checkOutDetail {
      order: 1;
      text-align: right;

      .top {
        .head {
          order: 2;
          text-align: right;
        }
      }

      .main {
        text-align: right;
      }
    }
  }

  #outlined-error-helper-text-helper-text {
    text-align: right;
  }

  .receiptSub {
    span.left {
      direction: rtl;
      order: 2;
    }
  }

  .receiptTotal {
    span.total {
      direction: rtl;
      order: 2;
    }
  }

  .languageIcon {
    .nav-link {
      font-size: 20px;
      margin: 10px 0 0 0;

      @media only screen and (max-width: 550px) {
        font-size: 24px;
        margin-bottom: 4px;
      }
    }
  }

  .languageMainPopup {
    .suggestedHeadingText {
      font-size: 19px;
      line-height: 27px;
      text-align: right;
    }

    .otherPopupInv {
      .dropdownAccordion {
        .customRadio {
          direction: rtl;

          .card {
            padding-left: 10px;

            .card-header {
              .LangName {
                font-size: 17px;
                text-align: right;
              }

              .LangCountry {
                font-size: 13px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .OtherPopup {
    .otherPopupInv {
      .bottomDialog .MuiDialogContent-root .MuiTypography-root {
        font-size: 13px !important;

        .icon-icon-lock {
          margin-left: 7px;
        }
      }

      .MuiDialogActions-spacing button {
        font-size: 17px !important;
      }

      .MuiTypography-h6 {
        font-size: 28px !important;
      }

      .MuiDialogContentText-root {
        font-size: 19px !important;
        line-height: 3rem;
        text-align: right;
        right: 0;
        margin-right: 50px;
      }

      .shippingCustomRadio .accordion .card .card-header span {

        font-size: 19px !important;
      }

      .dropdownAccordion {
        .card-body {
          ul li {
            font-size: 15px !important;
          }

          .MuiInputLabel-formControl {
            left: unset;
          }

          .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding-right: unset;
          }

          .languageMainPopup .otherPopupInv .dropdownAccordion .customRadio .card.activeShow .card-header {
            &:before {
              left: 9px;
              right: unset;
            }

            &:after {
              right: unset;
              left: 6px;
            }
          }

          ul li.active .tickIcon {
            &:before {
              left: 3px;
              margin: -4px -6px 0 0px;
              right: unset;
            }


            &:after {
              left: 0px;
              right: unset;
            }
          }
        }
      }
    }
  }



  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    right: unset;
  }

  .MuiAutocomplete-option {
    direction: rtl;
    text-align: right;
  }

  .MuiAutocomplete-popper {
    text-align: right;
    direction: rtl;
  }

  .customGmapWrapper {
    max-width: 411px;
    margin: 24px auto 0;
  }

  .MuiInputBase-root {
    font-size: 1.25rem;
  }

  .MuiFormHelperText-root {
    font-size: 1rem;
  }

  .FullScreenGmapWrapper {
    max-width: 1411px;
    // #ribbionParent {
    //     .mapRibbion {
    //         p {
    //             font-size: 19px;
    //             word-spacing: 3px;
    //             margin: 0px 7px;
    //             text-align: right;
    //         }
    //     }
    // }
  }

  .fullscreen-control {
    left: 0px;
    top: 10px !important;

    .fullscreen-location-pin-control {
      top: 10px !important;
    }
  }

  #ribbionParent {
    z-index: 10000001 !important;
    width: 100% !important;
    height: 45px !important;
    background: rgba(235, 245, 255, 1);
    z-index: 9999;
    display: flex;
    flex-direction: row;
    backdrop-filter: blur(157.781px);
    bottom: 0 !important;

    .mapRibbion {
      p {
        line-height: 45px;
        font-size: 19px;
        word-spacing: 3px;
        margin: 0px 7px;
        text-align: right;
      }
    }

    .mappinbtn {
      margin-left: unset;
    }
  }

  #mandatoryPin {
    height: 30px !important;

    .pinStripe {
      p {
        word-spacing: 0.2rem;
        font-size: 20px;
        text-align: right;
      }
    }
  }


  .react-tel-input .form-control {
    text-align: left;
  }

  .FullScreenAddressWrapper {
    right: 10px !important;
    left: unset !important;

    .address-pin-container {
      right: 10px;
      direction: ltr;
      // direction: rtl;
      left: unset;
      margin: 10px 0px -61px 10px;
      position: relative;
      display: flex;

      #map-address {
        text-align: left;
      }
    }
  }


  .MuiDialog-paperWidthSm {

    &.TermsPopup,
    .PrivacyPopup {
      text-align: right;
      direction: rtl;

      h5 {
        font-size: 1.75rem;
        &.MuiTypography-gutterBottom {
          margin-bottom: 0.25em;
        }
      }

      p {
        font-size: 1rem !important;
        &.MuiTypography-gutterBottom {
          margin-bottom: 0.25em;
        }
      }
    }
  }

  .footerWrap {
    padding: 10px 0 0 0;

    .container {
      .footer-main {
        direction: rtl;

        ul {
          margin: 0 10px 0 0px;
          padding: 0 10px 0 0px;
          border-right: 1px solid $light-grey;
          border-left: 0;

          li {
            span {
              margin-right: 0px;
              margin-left: 19px;
              font-size: 1rem;
            }

            a {
              margin-right: 0px;
              margin-left: 19px;
              font-size: 1rem;
            }

            @media only screen and (max-width: 480px) {
              a {
                font-size: 0.85rem;
              }
            }

            &:last-child {
              a {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  #privacyDialogContent {
    text-align: right;
    direction: rtl;
  }

  .browSetting {
    direction: rtl;
    text-align: right;
    font-size: 19px;
    line-height: 26px;
    word-spacing: 2px;
    ul {
        direction: rtl;
        text-align: right;
    }
  }

}